/**
 * Main front end point of entry.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";
import { AuthContext, CSExtras } from "@noom/hive-auth";
import { Provider as ReduxStoreProvider } from "@noom/hive-react";
import { LoaderView } from "@noom/wax-component-library";
import { ApiRegion, ApiEndpoint } from "@noom/community-web-uikit";
import { MixpanelLogger } from "@noom/hive-analytics";

import { App } from "@app/App";
import createStore from "@app/createStore";
import { initSentry } from "@app/sentry";
import { env } from "@app/env";

// Fix for the focus outline
import "focus-visible/dist/focus-visible";

const apiRegion = ApiRegion.US;
const apiEndpoint = ApiEndpoint.US;

const clientId = env.ONE_LOGIN_ID;
const apiKey = env.AMITY_API_KEY;
const adminPanelUrl = env.ADMIN_URL;
const csClientId = env.COACH_CLIENT_ID;
const csServerUrl = env.COACH_SERVER_URL;
const mixpanelToken = env.MIXPANEL_TOKEN;
const isDev = process.env.NODE_ENV === "development";

const sentryEnvironment = env.SENTRY_ENVIRONMENT;
const sentryDsn = env.SENTRY_DSN;

const csExtras: CSExtras = {
  packageName: "com.noom.web.community",
};

if (sentryDsn) {
  initSentry({
    dsn: sentryDsn,
    environment: sentryEnvironment,
    tracesSampleRate: 0.2,
  });
}

const store = createStore();

// Render the app
ReactDOM.render(
  <ReduxStoreProvider store={store}>
    <MixpanelLogger token={mixpanelToken} isDev={isDev}>
      <BrowserRouter>
        <AuthContext
          useCSAuth
          useAutoCookieAuth
          csExtras={csExtras}
          clientId={clientId}
          fallback={<LoaderView />}
          csClientId={csClientId}
          csServerUrl={csServerUrl}
          autoCookieName="autologin_data"
        >
          <App
            amityApiKey={apiKey}
            amityApiRegion={apiRegion}
            amityApiEndpoint={apiEndpoint}
            adminPanelUrl={adminPanelUrl}
          />
        </AuthContext>
      </BrowserRouter>
    </MixpanelLogger>
  </ReduxStoreProvider>,
  document.getElementById("root")
);
