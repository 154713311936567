import React from "react";
import { Container, ClientLoader } from "@noom/wax-component-library";

export function PageLoader() {
  return (
    <Container centerHorizontal centerVertical>
      <ClientLoader size="lg" />
    </Container>
  );
}
