import React, { ReactNode } from "react";
import { Box, Container, GlobalStyle } from "@noom/wax-component-library";

import { useContainerPadding, useNavbarHeight } from "../hooks";

export type AppLayoutProps = {
  navBar?: ReactNode;
  hideReCaptchaBadge?: boolean;
  children: ReactNode;
};

export function AppLayout({
  children,
  navBar,
  hideReCaptchaBadge,
}: AppLayoutProps) {
  const padding = useContainerPadding();
  const navbarHeight = useNavbarHeight();

  return (
    <Box display="flex" flexDir="column" minH="85vh">
      <Box
        top={0}
        width="100%"
        position="fixed"
        zIndex="sticky"
        bg="white"
        boxShadow="sm"
        height={navbarHeight}
        paddingX={padding}
      >
        {navBar}
      </Box>
      <Container
        flex={1}
        centerHorizontal
        w="100%"
        maxW="container.xl"
        pt={navbarHeight}
        paddingX={padding}
        boxSizing="border-box"
      >
        {children}
      </Container>

      {hideReCaptchaBadge ? (
        <GlobalStyle
          styles={{
            ".grecaptcha-badge": {
              visibility: "hidden",
            },
          }}
        />
      ) : null}
    </Box>
  );
}
