import React, { ReactElement, ReactNode } from "react";
import { Box, Container, IconButton, Text } from "@noom/wax-component-library";

import { isWebview } from "utils/checkIsInApp";
import { Logo } from "./Logo";
import { useSidebarType } from "../hooks";

export type EmptyNavBarProps = {
  children?: ReactNode;
  showNavigationMenu?: boolean;
  onLogoClick?: () => void;
  onNavigationMenuClick?: () => void;
  displayPathName?: string;
};

export function EmptyNavBar({
  onLogoClick,
  children,
  showNavigationMenu,
  onNavigationMenuClick,
  displayPathName,
}: EmptyNavBarProps): ReactElement {
  const navigationType = useSidebarType();

  return (
    <Box boxSizing="border-box" h="100%">
      <Container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        maxW="container.xl"
        h="100%"
        paddingX={0}
      >
        <Box display="flex" alignItems="center">
          {isWebview() && displayPathName && <Text>{displayPathName} </Text>}
          {showNavigationMenu && navigationType === "drawer" && (
            <IconButton
              h="100%"
              onClick={onNavigationMenuClick}
              icon={!isWebview() ? "hamburger" : "chevron-down"}
              bg="transparent"
              data-qa-anchor="hamburger-menu-button"
            />
          )}
          {!isWebview() && (
            <Logo data-testid="logo-component" ml={2} onClick={onLogoClick} />
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {children}
        </Box>
      </Container>
    </Box>
  );
}
