import React from "react";
import { StyleProps, Image } from "@noom/wax-component-library";

export function Logo({
  onClick,
  ...style
}: StyleProps & { onClick?: () => void }) {
  return (
    <>
      <Image
        src="/assets/img/noom-logo-icon.jpg"
        onClick={onClick}
        cursor={onClick ? "pointer" : "auto"}
        w="30px"
        h="30px"
        p={0}
        {...style}
      />
      <Image
        src="/assets/img/noom-logo.jpeg"
        onClick={onClick}
        cursor={onClick ? "pointer" : "auto"}
        w="110px"
        h="24px"
        p={0}
        paddingLeft={2}
        {...style}
      />
    </>
  );
}
