/**
 * Translation configuration
 *
 * Copyright (C) 2019D Noom, Inc.
 * @author nikola
 */

import i18next, { InitOptions } from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { DEFAULT_CONFIG } from "./constants";

export default function init(options: InitOptions = {}) {
  return i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({ ...DEFAULT_CONFIG, ...options });
}
