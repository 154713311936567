import React, { ReactElement, ReactNode, useMemo } from "react";
import { ButtonGroup } from "@noom/wax-component-library";

import { useActiveRoute } from "@modules/common";
import { paths } from "@app/paths";
import { useAmityMyCommunitiesList } from "@noom/community-web-uikit";
import { useTranslation } from "react-i18next";
import { EmptyNavBar, EmptyNavBarProps } from "./EmptyNavBar";

export type NavBarProps = {
  children?: ReactNode;
} & EmptyNavBarProps;
interface Community {
  communityId: string;
  displayName: string;
}
export function NavBar({
  onLogoClick,
  children,
  showNavigationMenu,
  onNavigationMenuClick,
}: NavBarProps): ReactElement {
  const { t } = useTranslation();
  const routeData = useActiveRoute(paths);
  const [communities = []] = useAmityMyCommunitiesList();
  const displayPathName = useMemo(() => {
    if (routeData?.communityId !== undefined && routeData?.communityId !== "") {
      if (Array.isArray(communities)) {
        return (
          (communities as Community[]).find(
            (community) => community.communityId === routeData?.communityId
          )?.displayName || ""
        );
      }
    }
    return routeData && routeData.key ? t(`${routeData.key}`) : "";
  }, [communities, routeData, t]);
  return (
    <EmptyNavBar
      showNavigationMenu={showNavigationMenu}
      onLogoClick={onLogoClick}
      onNavigationMenuClick={onNavigationMenuClick}
      displayPathName={displayPathName}
    >
      <ButtonGroup size="sm" colorScheme="gray" variant="link" spacing={4}>
        {children}
      </ButtonGroup>
    </EmptyNavBar>
  );
}
