import { useCallback, useMemo } from "react";
import { matchPath, useLocation, PathMatch } from "react-router-dom";

import { formatPath } from "./utils";

async function copyTextToClipboard(text: string) {
  window.NativeAndroid?.copyToClipboard(text);
  navigator.clipboard.writeText(text);
}

/** Copy full post and comment path to clipboard */
export function useCopyPath(paths: {
  userPost: string;
  userComment: string;
  communityPost: string;
  communityComment: string;
}) {
  const handleCopyPostPath = useCallback(
    (post: any) => {
      const path =
        window.location.origin +
        formatPath(
          post.targetType === "community"
            ? paths.communityPost
            : paths.userPost,
          {
            userId: post.postedUserId,
            communityId: post.targetId,
            postId: post.postId,
          }
        );

      copyTextToClipboard(path);
    },
    [paths]
  );

  const handleCopyCommentPath = useCallback(
    (comment: any) => {
      const communityId = /community\/(\w+)\/post/.exec(comment.path)?.[1];

      const path =
        window.location.origin +
        formatPath(communityId ? paths.communityComment : paths.userComment, {
          userId: comment.userId,
          communityId,
          postId: comment.referenceId,
          commentId: comment.commentId,
        });
      copyTextToClipboard(path);
    },
    [paths]
  );

  return {
    handleCopyPostPath,
    handleCopyCommentPath,
  };
}

/** Get current route information */
export function useActiveRoute(
  appRoutes: Record<string, string>
):
  | (PathMatch & { key: string; userId?: string; communityId?: string })
  | undefined {
  const location = useLocation();
  const routeData = useMemo(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(appRoutes)) {
      const match = matchPath(
        appRoutes[key as keyof typeof appRoutes],
        location.pathname
      );

      if (match) {
        return {
          ...match,
          key,
          userId: match.params?.userId,
          communityId: match.params?.communityId,
        };
      }
    }

    return undefined;
  }, [location.pathname, appRoutes]);

  return routeData;
}
