import qs from "query-string";

export function formatPath(
  path: string,
  pathParams: Record<string, string | undefined> = {},
  queryParams: Record<string, string | boolean | undefined> = {},
  hash = ""
) {
  let newPath = path;
  Object.keys(pathParams).forEach((key) => {
    newPath = newPath.replace(`:${key}`, pathParams[key] ?? "");
  });

  if (Object.keys(queryParams).length) {
    newPath += `?${qs.stringify(queryParams)}`;
  }

  if (hash) {
    newPath += `#${hash}`;
  }

  return newPath;
}
