import { createStore, combineReducers } from "@noom/hive-core";

import {
  baseCoachServerApi,
  baseCommunityServerApi,
} from "modules/dataFetching";

const rootReducer = combineReducers({
  [baseCoachServerApi.reducerPath]: baseCoachServerApi.reducer,
  [baseCommunityServerApi.reducerPath]: baseCommunityServerApi.reducer,
});

const middleware = [
  baseCoachServerApi.middleware,
  baseCommunityServerApi.middleware,
];

type CombinedState = ReturnType<typeof rootReducer>;

// App store declared globally so it can be referenced anywhere
// without cycle imports
declare global {
  export type AppState = CombinedState;
}

export default function create() {
  return createStore({
    rootReducer,
    middleware,
    includeLoggerInDevelopment: false,
  });
}
