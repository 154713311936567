/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useBreakpointValue, Spacing } from "@noom/wax-component-library";

type SidebarType = "drawer" | "sticky";

export function useSidebarType(): SidebarType {
  return useBreakpointValue({
    base: "drawer",
    lg: "sticky",
  }) as SidebarType;
}

export function useContainerPadding(): number {
  return useBreakpointValue({ base: 1, md: 4 }) as number;
}

export function useMinimizedComponent(): boolean {
  return useBreakpointValue({ base: true, md: false }) as boolean;
}

export function useNavbarHeight(): number {
  return useBreakpointValue({ base: Spacing[16], md: Spacing[12] }) as number;
}

export function useScrollTop(
  viewId?: string,
  elementId?: string,
  scrollToElementTimeout = 1000
) {
  // Convert spacing to pixels
  const navBar = (useNavbarHeight() / 5) * 16;

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });

    if (elementId) {
      setTimeout(() => {
        const elementTop =
          document.getElementById(elementId.replace("#", ""))?.offsetTop ?? 0;
        if (elementTop) {
          window.scroll({
            top: elementTop - navBar,
            left: 0,
          });
        }
      }, scrollToElementTimeout);
    }
  }, [viewId, elementId]);
}
