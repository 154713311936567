import { getActiveUser } from "@noom/hive-auth";
import { baseQuery } from "@noom/hive-core";
import { createApi } from "@noom/hive-react";

export const baseCommunityServerApi = createApi({
  reducerPath: "communityServerApi",
  baseQuery: baseQuery({
    baseURL: process.env.HIVE_COMMUNITY_SERVER_URL,
    prepareHeaders: () => {
      return {
        Authorization: `Bearer ${getActiveUser()?.accessToken}`,
      };
    },
  }),
  endpoints: () => ({}),
});
