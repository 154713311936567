/**
 * Top level component
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import React, { Suspense, ReactElement, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import {
  LoginCallback,
  Login,
  Logout,
  SecureRoute,
  PublicRoute,
} from "@noom/hive-auth";
import {
  extendCompassTheme,
  ThemeProvider,
  CompassColor,
  generateColorPalette,
} from "@noom/wax-component-library";

import { paths } from "@app/paths";
import { withSentryRoutes } from "@app/sentry";

import LoadingPage from "pages/LoadingPage";

import { init as init18n } from "modules/localization";
init18n();

const AppContainer = lazy(() => import("pages/AppContainer"));
const ExplorePage = lazy(() => import("pages/ExplorePage"));
const CategoryCommunitiesPage = lazy(() =>
  import("pages/CategoryCommunitiesPage")
);
const CommunityEditPage = lazy(() => import("pages/CommunityEditPage"));
const CommunityFeedPage = lazy(() => import("pages/CommunityFeedPage"));
const NewsFeedPage = lazy(() => import("pages/NewsFeedPage"));
const UserEditPage = lazy(() => import("pages/UserEditPage"));
const UserFeedPage = lazy(() => import("pages/UserFeedPage"));
const UserPostPage = lazy(() => import("pages/UserPostPage"));
const CommunityPostPage = lazy(() => import("pages/CommunityPostPage"));
const LoginSelectPage = lazy(() => import("pages/LoginSelectPage"));
const LoginFormPage = lazy(() => import("pages/LoginFormPage"));
const SettingsPage = lazy(() => import("pages/SettingsPage"));

const theme = extendCompassTheme({
  styles: {
    global: {
      ".rdt.top .rdtPicker": {
        top: "auto",
        bottom: "100%",
      },
      "html, body": {
        bg: "#F6F4EE",
        fontSize: "14px",
      },
      "html, body, #root": {
        minH: "100vh",
      },
      "body > iframe": {
        display: "none",
      },
      "body, #root": {
        minH: "85vh",
      },
    },
  },

  sizes: {
    container: {
      "2xl": "1440px",
    },
  },

  shadows: {
    outline: `0 0 0 2px ${CompassColor.tarocco}`,
  },

  colors: {
    secondary: generateColorPalette(CompassColor.lagoon),
  },

  components: {
    Heading: {
      baseStyle: {
        fontWeight: "bold",
      },
      sizes: {
        xxl: {
          fontSize: "2.5rem",
        },
        xl: {
          fontSize: "2rem",
        },
        lg: {
          fontSize: "1.5rem",
        },
      },
    },
  },
});

const SentryRoutes = withSentryRoutes(Routes);

export type AppProps = {
  amityApiKey: string;
  amityApiRegion: string;
  amityApiEndpoint: string;
  adminPanelUrl: string;
};

export function App({
  amityApiRegion,
  amityApiEndpoint,
  amityApiKey,
  adminPanelUrl,
}: AppProps): ReactElement {
  return (
    <ThemeProvider resetCSS theme={theme}>
      <Suspense fallback={<LoadingPage />}>
        <SentryRoutes>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <LoginSelectPage />
              </PublicRoute>
            }
          />
          <Route path="/login/sso" element={<Login />} />
          <Route
            path="/login/form"
            element={
              <PublicRoute>
                <LoginFormPage />
              </PublicRoute>
            }
          />
          <Route path="/login/callback" element={<LoginCallback />} />
          <Route path="/logout" element={<Logout />} />

          {/* Any components that require authentication should be wrapped in a SecureRoute */}
          <Route
            path="/*"
            element={
              <SecureRoute>
                <AppContainer
                  apiKey={amityApiKey}
                  apiRegion={amityApiRegion}
                  apiEndpoint={amityApiEndpoint}
                  adminPanelUrl={adminPanelUrl}
                >
                  <Routes>
                    <Route path={paths.explore} element={<ExplorePage />} />
                    <Route
                      path={paths.category}
                      element={<CategoryCommunitiesPage />}
                    />

                    <Route
                      path={paths.communityFeed}
                      element={<CommunityFeedPage />}
                    />
                    <Route
                      path={paths.communityEdit}
                      element={<CommunityEditPage />}
                    />

                    <Route path={paths.userFeed} element={<UserFeedPage />} />
                    <Route path={paths.userEdit} element={<UserEditPage />} />
                    <Route path={paths.newsFeed} element={<NewsFeedPage />} />

                    <Route path={paths.userPost} element={<UserPostPage />} />
                    <Route
                      path={paths.userComment}
                      element={<UserPostPage />}
                    />
                    <Route
                      path={paths.communityPost}
                      element={<CommunityPostPage />}
                    />
                    <Route
                      path={paths.communityComment}
                      element={<CommunityPostPage />}
                    />

                    <Route path={paths.settings} element={<SettingsPage />} />

                    <Route
                      path="*"
                      element={<Navigate to={paths.newsFeed} />}
                    />
                  </Routes>
                </AppContainer>
              </SecureRoute>
            }
          />
        </SentryRoutes>
      </Suspense>
    </ThemeProvider>
  );
}
