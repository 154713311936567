export const paths = {
  newsFeed: "/",
  explore: "/explore",
  category: "/category/:categoryId",
  communityFeed: "/community/:communityId",
  communityNew: "/community/new",
  communityEdit: "/community/:communityId/edit",
  communityPost: "/community/:communityId/post/:postId",
  communityComment: "/community/:communityId/post/:postId/comment/:commentId",
  userFeed: "/user/:userId",
  userEdit: "/user/:userId/edit",
  userPost: "/user/:userId/post/:postId",
  userComment: "/user/:userId/post/:postId/comment/:commentId",
  notifications: "/notifications",
  settings: "/settings",
};

export default paths;
