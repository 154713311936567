/* eslint-disable no-param-reassign */
export function throwIfNullOrUndefinedInProd(
  input: string | null | undefined,
  paramName: string
): string {
  if (input != null && input !== undefined) {
    return input;
  }
  if (process.env.NODE_ENV !== "test") {
    throw new Error(
      `Expected ${paramName} not to be null or undefined.\n\n
      If you are defining an ENV variable, make sure to include the 
      "HIVE_" prefix so hive-scripts propagates it correctly.\n\n`
    );
  } else {
    return "";
  }
}

/**
 * Add any optional environment variables here
 */
const OPTIONAL_ENV_VARS_KEYS = ["SENTRY_ENVIRONMENT", "SENTRY_DSN"] as const;

/**
 * Add any required environment variables here
 */
const REQUIRED_ENV_VARS_KEYS = [
  "ONE_LOGIN_ID",
  "AMITY_API_KEY",
  "ADMIN_URL",
  "COACH_CLIENT_ID",
  "COACH_SERVER_URL",
  "MIXPANEL_TOKEN",
  "MANATEE_URL",
  "DASHBOARD_URL",
] as const;

type RequiredEnvVarsType = Record<
  typeof REQUIRED_ENV_VARS_KEYS[number],
  string
>;
type OptionalEnvVarsType = Partial<
  Record<typeof OPTIONAL_ENV_VARS_KEYS[number], string>
>;

type ENV_VARIABLES = RequiredEnvVarsType & OptionalEnvVarsType;

const REQUIRED_ENV_VARIABLES = REQUIRED_ENV_VARS_KEYS.reduce<
  RequiredEnvVarsType
>((agg, val) => {
  agg[val] = throwIfNullOrUndefinedInProd(
    process.env[`HIVE_${val}`],
    `process.env.HIVE_${val}`
  );
  return agg;
}, {} as RequiredEnvVarsType);

const OPTIONAL_ENV_VARIABLES = OPTIONAL_ENV_VARS_KEYS.reduce<
  OptionalEnvVarsType
>((agg, val) => {
  agg[val] = process.env[`HIVE_${val}`] ?? undefined;
  return agg;
}, {} as OptionalEnvVarsType);

export const env: ENV_VARIABLES = {
  ...REQUIRED_ENV_VARIABLES,
  ...OPTIONAL_ENV_VARIABLES,
};
