import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

export type SentryConfig = {
  dsn: string;
  environment?: string;
  tracesSampleRate?: number;
};

export function initSentry(config: SentryConfig) {
  const { dsn, environment = "development", tracesSampleRate = 1.0 } = config;

  Sentry.init({
    // The DSN tells the SDK where to send the events.
    dsn,

    // List of included Sentry integrations
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),

      new Sentry.Integrations.Breadcrumbs({
        console: true,
        xhr: true,
        fetch: true,
        sentry: true,
        dom: {
          serializeAttribute: ["data-qa-anchor", "id", "class"],
        },
      }),
    ],

    // Sets the environment. This string is freeform and not set by default. (think staging vs production or similar).
    environment,

    // A number between 0 and 1, controlling the percentage chance a given transaction will be sent to Sentry.
    // (0 represents 0% while 1 represents 100%.) Applies equally to all transactions created in the app.
    tracesSampleRate,

    // A list of strings or regex patterns that match error URLs which should exclusively be sent to Sentry.
    // By default, all errors will be sent. This is a "contains" match to the entire file URL.
    denyUrls: [/api-js\.mixpanel\.com/i],
  });
}

export function withSentryRoutes(routesElement: React.FC) {
  return Sentry.withSentryReactRouterV6Routing(routesElement);
}
