import React from "react";
import { useNavigate } from "react-router-dom";

import { AppLayout, EmptyNavBar, PageLoader } from "modules/layout";

export default function LoaderPage() {
  const navigate = useNavigate();

  return (
    <AppLayout navBar={<EmptyNavBar onLogoClick={() => navigate("/")} />}>
      <PageLoader />
    </AppLayout>
  );
}
