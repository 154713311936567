import React, { ReactNode, PropsWithChildren, memo } from "react";
import {
  Box,
  Stack,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
  DrawerHeader,
  IconButton,
} from "@noom/wax-component-library";

import { Logo } from "./Logo";
import { useSidebarType, useContainerPadding, useNavbarHeight } from "../hooks";

function LayoutDrawer({
  children,
  isOpen,
  onClose,
  placement,
  showLogo,
}: PropsWithChildren<Pick<DrawerProps, "isOpen" | "onClose" | "placement">> & {
  showLogo?: boolean;
}) {
  const navbarHeight = useNavbarHeight();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement={placement}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent transform="none !important">
        {showLogo && (
          <DrawerHeader
            h={navbarHeight}
            p={2}
            display="flex"
            alignItems="center"
            flexDir="row"
          >
            <IconButton
              h="100%"
              onClick={onClose}
              icon="close"
              bg="transparent"
            />
            <Logo ml={2} />
          </DrawerHeader>
        )}
        <DrawerBody
          paddingX={4}
          css={{ "> div": { width: "100%", paddingTop: 0 } }}
        >
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

const MemoLayoutDrawer = memo(LayoutDrawer);

export type ContentWithSidebarLayoutProps = {
  asideLeft: ReactNode;
  asideRight?: ReactNode;
  children: ReactNode;
  isLeftDrawerOpen?: boolean;
  onLeftDrawerClose: () => void;
};

export function ContentWithSidebarLayout({
  children,
  asideLeft,
  asideRight,
  isLeftDrawerOpen = false,
  onLeftDrawerClose,
}: ContentWithSidebarLayoutProps) {
  const sidebar = useSidebarType();
  const padding = useContainerPadding();
  const navbarHeight = useNavbarHeight();

  return (
    <Stack
      maxW="container.2xl"
      w="100%"
      isInline
      spacing={4}
      shouldWrapChildren={false}
    >
      {sidebar === "sticky" ? (
        <Box position="relative" w={[0, 0, 0, 280, 280]}>
          <Box position="fixed" h="100vh" pb={navbarHeight} zIndex="sticky">
            {asideLeft}
          </Box>
        </Box>
      ) : (
        <MemoLayoutDrawer
          showLogo
          isOpen={isLeftDrawerOpen}
          onClose={onLeftDrawerClose}
          placement="left"
        >
          {asideLeft}
        </MemoLayoutDrawer>
      )}

      <Box
        pt={padding * 2}
        flex={1}
        minW={0}
        css={
          sidebar === "drawer" && {
            "> div": { width: "100%", maxWidth: "initial" },
          }
        }
      >
        {children}
      </Box>
      {asideRight && (
        <Box w={[0, 0, 0, 0, 280]}>
          <Box position="fixed" h="100vh" pb={navbarHeight} zIndex="sticky">
            {asideRight}
          </Box>
        </Box>
      )}
    </Stack>
  );
}
