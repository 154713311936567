export const DEFAULT_LANGUAGES: Record<string, string> = {
  en: "English",
  es: "Español",
  de: "Deutsch",
};

export const DEFAULT_NAMESPACES = ["common"];

const isTest = process.env.NODE_ENV === "test";

export const DEFAULT_CONFIG = {
  debug: false,
  initImmediate: false,
  preload: ["en"],
  fallbackLng: "en",
  whitelist: Object.keys(DEFAULT_LANGUAGES),
  interpolation: {
    escapeValue: false,
  },
  ns: DEFAULT_NAMESPACES,
  defaultNS: DEFAULT_NAMESPACES[0],
  fallbackNS: DEFAULT_NAMESPACES[0],
  backend: {
    loadPath: "/assets/locales/{{lng}}/{{ns}}.json",
  },
  detection: {
    lookupQuerystring: "lang",
    checkWhitelist: true,
  },
  react: {
    useSuspense: !isTest, // Suspense is consing Enzyme tests to fail
  },
};
