import React, { ReactNode } from "react";
import { Box } from "@noom/wax-component-library";

export type CenteredLayoutProps = {
  children: ReactNode;
  centerVertical?: boolean;
};

export function CenteredLayout({
  children,
  centerVertical,
}: CenteredLayoutProps) {
  return (
    <Box
      display="flex"
      maxW="container.2xl"
      w="100%"
      pt={12}
      paddingX={4}
      justifyContent="center"
      alignItems={centerVertical ? "center" : "start"}
    >
      {children}
    </Box>
  );
}
